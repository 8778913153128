import { Component, NgZone, OnInit } from '@angular/core';

import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Router, NavigationStart } from '@angular/router';
import { AuthService } from './services/authService';
import { AnalyticsService } from './providers/analytics/analytics';
import { environment, GOOGLE_MAPS_API_KEY, GLASSFY_API_KEY, API_URL } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { StorageService } from './services/storage.service';
import { Glassfy } from "capacitor-plugin-glassfy";
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { UserModel } from './models/userModel';
Chart.register(annotationPlugin);
import { register } from 'swiper/element/bundle';
import { APP_VERSION } from './app.version';


// import { ENV } from '@config';

register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    public menuCtrl: MenuController,
    private analyticsService: AnalyticsService,
    private storageService: StorageService,
    private router: Router,
    private title: Title,
    private authService: AuthService,
    private apollo: Apollo,
    private ngZone: NgZone
  ) {
    this.initializeApp();
  }

  api = API_URL
  user: UserModel
  version = APP_VERSION


  closeMenu() {
    this.menuCtrl.close();
  }

  toggleMenu() {
    this.menuCtrl.toggle();
  }

  logout() {
    this.authService.logout().subscribe(succ => {
      this.storageService.remove('loginSession')
        .then(
          data => console.log(data),
          error => console.error(error)
        );
      this.closeMenu()
      this.router.navigate(['']);
      window.location.reload();
    });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.analyticsService.startTrackerWithId(environment.GA_TRACKER_ID);
      if (this.platform.is("ios")) {
        try {
          await Glassfy.initialize({ apiKey: GLASSFY_API_KEY, watcherMode: false });
        } catch (e) {
          // initialization error
        }
      }

      try {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=` + GOOGLE_MAPS_API_KEY;
        document.head.appendChild(script);
      }
      catch (error) {
        console.log("Couldn't append google maps")
        console.log(error)
      }
      this.router.events
        .subscribe(event => {
          //observe router and when it start navigation it will track the view
          if (event instanceof NavigationStart) {
            let title = this.title.getTitle();
            //get title if it was sent on state
            if (this.router.getCurrentNavigation().extras.state) {
              title = this.router.getCurrentNavigation().extras.state.title;
            }
            //pass url and page title
            this.analyticsService.trackView(event.url, title);
          }
        });
      this.splashScreen.hide();
    });
  }

  async ngOnInit() {
  
    // this.apollo.watchQuery<any>({
    //   query: GetProfile,
    // })
    //   .valueChanges
    //   .subscribe(result => {
    //     // const user = result.data.me
    //     console.log(result)
    //   })
    
  }


}

