import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'log-list',
    loadChildren: () => import('./log-list/log-list.module').then(m => m.LogListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'trophy-room',
    loadChildren: () => import('./trophy-room/trophy-room.module').then(m => m.TrophyRoomPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'log-detail/:id',
    loadChildren: () => import('./log-detail/log-detail.module').then(m => m.LogDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'trail-cam-sighting-log-detail/:id',
    loadChildren: () => import('./trail-cam-sighting-log-detail/trail-cam-sighting-log-detail.module').then( m => m.TrailCamSightingLogDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'dashboard/:dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'trophy-harvest-detail/:harvestId',
    loadChildren: () => import('./trophy-room/trophy-harvest-detail/trophy-harvest-detail.module')
      .then(m => m.TrophyHarvestDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'history',
    loadChildren: () => import('./history/history.module').then(m => m.HistoryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'season-review/:seasonId',
    loadChildren: () => import('./history/season-review/season-review.module').then(m => m.SeasonReviewPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then(m => m.AboutPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'about-detail/:title',
    loadChildren: () => import('./about/about-detail/about-detail.module').then(m => m.AboutDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'spots',
    loadChildren: () => import('./spots/spots.module').then(m => m.SpotsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'weather-pattern-home',
    loadChildren: () => import('./weather-pattern-home/weather-pattern-home.module').then( m => m.WeatherPatternHomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'spot-detail/:spotId',
    loadChildren: () => import('./spots/spot-detail/spot-detail.module').then(m => m.SpotDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'equipment',
    loadChildren: () => import('./equipment/equipment.module').then(m => m.EquipmentPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'trophies',
    loadChildren: () => import('./trophies/trophies.module').then(m => m.TrophiesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'trophy-detail/:trophyId',
    loadChildren: () => import('./trophies/trophy-detail/trophy-detail.module').then(m => m.TrophyDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'visit-profile/:userId',
    loadChildren: () => import('./visit-profile/visit-profile.module').then(m => m.VisitProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'trail-cam-detail/:trailCamId',
    loadChildren: () => import('./equipment/trail-cam-detail/trail-cam-detail.module').then(m => m.TrailCamDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'trail-cam-log-detail/:trailCamId/:trailCamLogId',
    loadChildren: () => import('./equipment/trail-cam-log-detail/trail-cam-log-detail.module').then(m => m.TrailCamLogDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'item-detail/:id',
    loadChildren: () => import('./equipment/item-detail/item-detail.module').then(m => m.ItemDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'item-detail-tutorial/:type',
    loadChildren: () => import('./equipment/item-detail-tutorial/item-detail-tutorial.module').then( m => m.ItemDetailTutorialPageModule)
  },
  {
    path: 'sub-item-detail/:type/:id',
    loadChildren: () => import('./equipment/sub-item-detail/sub-item-detail.module').then(m => m.SubItemDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sub-item-detail-tutorial/:type',
    loadChildren: () => import('./equipment/sub-item-detail-tutorial/sub-item-detail-tutorial.module').then( m => m.SubItemDetailTutorialPageModule)
  },
  {
    path: 'stand-detail/:spotId/:standId',
    loadChildren: () => import('./spots/stand-detail/stand-detail.module').then(m => m.StandDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'verify-email/:token',
    loadChildren: () => import('./verify-email/verify-email.module').then(m => m.verifyEmailPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'stand-tutorial',
    loadChildren: () => import('./spots/stand-tutorial/stand-tutorial.module').then( m => m.StandTutorialPageModule)
  },
  {
    path: 'spot-tutorial',
    loadChildren: () => import('./spots/spot-tutorial/spot-tutorial.module').then( m => m.SpotTutorialPageModule)
  }, 
  {
    path: 'trophy-detail-tutorial',
    loadChildren: () => import('./trophies/trophy-detail-tutorial/trophy-detail-tutorial.module').then( m => m.TrophyDetailTutorialPageModule)
  },
  {
    path: 'trail-cam-detail-tutorial',
    loadChildren: () => import('./equipment/trail-cam-detail-tutorial/trail-cam-detail-tutorial.module').then( m => m.TrailCamDetailTutorialPageModule)
  },
  {
    path: 'social',
    loadChildren: () => import('./social/social.module').then( m => m.SocialPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'social-post-detail/:postId',
    loadChildren: () => import('./social/social-post-detail/social-post-detail.module').then( m => m.SocialPostDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'insights',
    loadChildren: () => import('./insights/insights.module').then( m => m.InsightsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'trip-planner',
    loadChildren: () => import('./trip-planner/trip-planner.module').then( m => m.TripPlannerPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./unauthorized/unauthorized.module').then( m => m.UnauthorizedPageModule)
  },

  {
    path: '**',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
