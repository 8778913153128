import gql from "graphql-tag";

export const AddUser = gql`
mutation addUser($username: String!, $firstName: String!, $lastName: String!, $zip: String!, $password: String!, $email: String!, $profilePictureName: String, $dashboards: String){
	addUser(username: $username, firstName: $firstName, lastName: $lastName, email: $email, zip: $zip, password: $password, profilePictureName: $profilePictureName, dashboards: $dashboards){
    id
    username
    zip
    level
    exp
  }
}`

export const AddFirebaseTokenToUser = gql`
mutation addFirebaseTokenToUser($token: String!){
  addFirebaseToken(token: $token)
}`

export const AddTrailCamLog = gql`
mutation addTrailCamLog($trailCamId: Int!, $typeOfDeer: String, $attractant: String, $timeInField: Int, $moveAmt: Int, $rating: Int, $notes: String, $startDate: Date, $endDate: Date, $makePublic: Boolean, $consistentDaylightMovement: Boolean, $movementTime: Int, $spotId: Int, $trophies: [String], $trophySightings: [TrophySightingMutation]){
 addTrailCamLog(trailCamId: $trailCamId, typeOfDeer: $typeOfDeer, attractant: $attractant, timeInField: $timeInField, moveAmt: $moveAmt, rating: $rating, notes: $notes, startDate: $startDate, endDate: $endDate, makePublic: $makePublic, consistentDaylightMovement: $consistentDaylightMovement, movementTime: $movementTime, spotId: $spotId, trophies: $trophies, trophySightings: $trophySightings)
}`

export const AddMapFeature = gql`
mutation addMapFeature($spotId: Int!, $standId: Int, $trailCamId: Int, $name: String, $type: String, $description: String, $points: [PointMutation]){
  addMapFeature(spotId: $spotId, standId: $standId, trailCamId: $trailCamId, name: $name, type: $type, description: $description, points: $points)
}`

export const AddTrailCamSightingLog = gql`
mutation AddTrailCamSightingLog($spotId: Int!, $trophyId: Int, $dateTime: String!, $pattern: String, $observing: String, $tags: String) {
  addTrailCamSightingLog(spotId: $spotId, trophyId: $trophyId, dateTime: $dateTime, pattern: $pattern, observing: $observing, tags: $tags)
}`


export const AddNewStand = gql`
mutation addStandToSpot($spotId: Int!, $name:String, $description: String, $weapon: String, $huntingOver: String, $accessDirection: String, $singleHunterInd: Boolean, $weaponSpecificInd: Boolean, $winds: [WindMutation]){
  addStandToSpot(spotId: $spotId, name: $name, description: $description, weapon: $weapon, huntingOver: $huntingOver, accessDirection: $accessDirection, singleHunterInd: $singleHunterInd, weaponSpecificInd: $weaponSpecificInd, winds: $winds)
}`

export const AddLike = gql`
mutation AddLike($postId: Int!){
  addLike(postId: $postId){
    id
    hasLiked
    likes {
      user {
        id
        username
        level
        firstName
        relationship
        lastName
        isSelf
        profilePicture {
          id
        }
      }
    }
  }
}`

export const UnLike = gql`
mutation UnLike($postId: Int!){
  deleteLike(postId: $postId){
    id
    hasLiked
    likes {
      user {
        id
        username
        level
        firstName
        relationship
        lastName
        isSelf
        profilePicture {
          id
        }
      }
    }
  }
}`

export const CommentPost = gql`
mutation AddComment($postId: Int!, $comment: String, $createdAt: String) {
  addComment(postId: $postId, comment: $comment, createdAt: $createdAt) {
    id
    comments {
      id
      comment
      createdAt
      isOwn
      user {
        id
        isSelf
        username
        profilePicture {
          id
        }
      }
    }
  }
}`

export const EditPost = gql`
mutation EditSocialPost($postId: Int!, $post: String) {
  editSocialPost(postId: $postId, post: $post) {
    id
    user {
      id
      username
      profilePicture {
        id
      }
      badges {
        id
      }
    }
    pictures {
      id
    }
    post
    createdAt
    date
    privatePost
    isOwnPost
    hasLiked
    likes {
      user {
        id
        username
        level
        firstName
        relationship
        lastName
        isSelf
        profilePicture {
          id
        }
      }
    }
    comments {
      id
      comment
      createdAt
      isOwn
      user {
        id
        isSelf
        username
        profilePicture {
          id
        }
      }
    }
    publicLog {
      id
      dashboardInd
      pictures {
        id
      }
    }
  }
}`

export const DeletePost = gql`
mutation DeleteSocialPost($postId: Int!) {
  deleteSocialPost(postId: $postId)
}`

export const DeleteComment = gql`
mutation DeleteComment($commentId: Int!) {
  deleteComment(commentId: $commentId) {
    id
    comments {
      id
      comment
      createdAt
      isOwn
      user {
        id
        isSelf
        username
        profilePicture {
          id
        }
      }
    }
  }
}`

export const CreateNewTrailCam = gql`
mutation createNewTrailCam($name: String!, $make: String, $model: String, $year: Int, $buddies: [String]){
  addTrailCam(name: $name, make: $make, model: $model, year: $year, buddies: $buddies)
}`

export const EditItem = gql`
mutation editItem($gearBagItemId: Int!, $name: String, $activeInd: Boolean, $length: Int, $action: String, $isElectronic: Boolean){
  editItem(gearBagItemId: $gearBagItemId, name: $name, activeInd: $activeInd, length: $length, action: $action, isElectronic: $isElectronic)
}`

export const CreateNewItem = gql`
 mutation addItemToGearbag($name: String!, $type: String, $action: String, $isElectronic: Boolean, $length: Int, $configType: String, $caliber: String, $sight: String, $choke: String, $stabilizer: Int){
  addItemToGearBag(name: $name, type: $type, isElectronic:$isElectronic, length: $length, action: $action, configType: $configType, caliber: $caliber, sight: $sight, choke: $choke, stabilizer: $stabilizer)
}`

export const AddConfigToItem = gql`
mutation addConfigToItem($gearBagItemId: Int!, $name: String!, $reelId: Int, $lineId: Int, $ammoId: Int, $broadheadId: Int, $type: String, $caliber: String, $sight: String, $choke: String, $stabilizer: Int) {
  addConfigToGearBagItem(gearBagItemId: $gearBagItemId, name: $name, reelId: $reelId, lineId: $lineId, ammoId: $ammoId, broadheadId: $broadheadId, type: $type, caliber: $caliber, sight: $sight, choke: $choke, stabilizer: $stabilizer)
}`

export const AddNewReel = gql`
mutation addReel($name: String!, $type: String, $gearRatio: String){
  addReelToGearBag(name: $name, type: $type, gearRatio: $gearRatio)
}`

export const AddNewBroadhead = gql`
mutation addBroadhead($name: String!, $type: String, $brand: String, $blades: Int, $tip: String, $cuttingDiameter: String, $weight: String){
  addBroadheadToGearBag(name: $name, type: $type, brand: $brand, blades: $blades, tip: $tip, cuttingDiameter: $cuttingDiameter, weight: $weight)
}`

export const AddNewAmmmo = gql`
mutation addAmmo($name: String!, $type: String, $brand: String, $weightShot: String, $powderType: String, $shellLength: String, $shotType: String, $bulletType: String, $caliber: String, $fps: Int){
  addAmmoToGearBag(name: $name, type: $type, brand: $brand, weightShot: $weightShot, powderType: $powderType, shellLength: $shellLength, shotType: $shotType, bulletType: $bulletType, caliber: $caliber, fps: $fps)
}`

export const AddNewLine = gql`
mutation addLine($name: String!, $type: String, $brand: String, $color: String, $lbsTest: Int){
  addLineToGearBag(name: $name, type: $type, brand: $brand, color: $color, lbsTest: $lbsTest)
}`

export const EditTrophy = gql`
mutation editTrophy($trophyId: Int!, $speciesId: Int!, $spotId: Int!, $name: String, $recognizeFeatures: String, $activeInd: Boolean, $hitListInd: Boolean, $notes: String, $age: Float, $approxScore: Int, $inactiveReason: Int, $priority: Int, $points: Int, $yearsHistory: Int, $buddies: [String]){
  editTrophy(trophyId: $trophyId, speciesId: $speciesId, spotId: $spotId, name: $name, recognizeFeatures: $recognizeFeatures, activeInd: $activeInd, hitListInd: $hitListInd, notes: $notes, age: $age, approxScore: $approxScore, inactiveReason: $inactiveReason, priority: $priority, points: $points yearsHistory: $yearsHistory, buddies: $buddies)
}`

export const EditCam = gql`
  mutation editCam($trailCamId: Int!, $make: String, $model: String, $year: Int, $checkedDate: Date, $name: String, $attractant: String, $inFieldInd: Boolean, $spotId: Int, $activeInd: Boolean, $buddies: [String]){
    editTrailCam(trailCamId:$trailCamId, checkedDate: $checkedDate, name: $name, make: $make, model: $model, year: $year, attractant: $attractant, inFieldInd: $inFieldInd, spotId: $spotId, activeInd: $activeInd, buddies: $buddies)
  }`

export const CheckCam = gql`
  mutation checkedCam($trailCamId: Int!, $checkedDate: Date){
    checkTrailCam(trailCamId:$trailCamId, checkedDate: $checkedDate)
  }`

export const EditAmmo = gql`
mutation EditAmmo($ammoId: Int!, $name: String!, $type: String, $brand: String, $weightShot: String, $powderType: String, $shellLength: String, $shotType: String, $bulletType: String, $caliber: String, $fps: Int, $activeInd: Boolean){
  editAmmo(ammoId: $ammoId, name: $name, type: $type, brand: $brand, weightShot: $weightShot, powderType: $powderType, shellLength: $shellLength, shotType: $shotType, bulletType: $bulletType, caliber: $caliber, fps: $fps, activeInd: $activeInd)
}`

export const EditBaitPreset = gql`
mutation EditBaitPreset($baitPresetId: Int!, $type: String, $name: String, $primaryColor: String, $secondaryColor: String, $isLive: Boolean, $noiseInd: Boolean, $flashInd: Boolean, $smellInd: Boolean, $freshInd: Boolean, $additional: String, $modifications: String, $billType: String, $weight: String, $divingDepth: String, $activeInd: Boolean) {
  editBait(baitPresetId: $baitPresetId, type: $type, name: $name, primaryColor: $primaryColor, secondaryColor: $secondaryColor, isLive: $isLive, noiseInd: $noiseInd, flashInd: $flashInd, smellInd: $smellInd, freshInd: $freshInd, additional: $additional, modifications: $modifications, billType: $billType, weight: $weight, divingDepth: $divingDepth, activeInd: $activeInd)
}`

export const EditLine = gql`
mutation EditLine($lineId: Int!, $name: String!, $type: String, $brand: String, $color: String, $lbsTest: Int, $activeInd: Boolean){
  editLine(lineId: $lineId, name: $name, type: $type, brand: $brand, color: $color, lbsTest: $lbsTest, activeInd: $activeInd)
}`

export const EditReel = gql`
mutation EditReel($reelId: Int!, $name: String!, $type: String, $gearRatio: String, $activeInd: Boolean){
  editReel(reelId: $reelId, name: $name, type: $type, gearRatio: $gearRatio, activeInd: $activeInd)
}`

export const EditBroadhead = gql`
mutation EditBroadhead($headId: Int!, $name: String!, $type: String, $brand: String, $blades: Int, $tip: String, $cuttingDiameter: String, $weight: String, $activeInd: Boolean){
  editHead(headId: $headId, name: $name, type: $type, brand: $brand, blades: $blades, tip: $tip, cuttingDiameter: $cuttingDiameter, weight: $weight, activeInd: $activeInd)
}`

export const EditProfile = gql`
mutation EditProfile($firstName: String, $lastName: String, $zip: String, $email: String, $bio: String){
  editProfile(firstName: $firstName, lastName: $lastName, zip: $zip, email: $email, bio: $bio)
}`

export const ChangePassword = gql`
mutation ChangePassword($oldPassword: String!, $newPassword: String!){
  updatePassword(oldPassword: $oldPassword, newPassword: $newPassword)
}`

export const AddLog = gql`
mutation AddLog(
  $logData: LogMutation!,
  $weatherData: WeatherMutation!,
  $gameStats: [GameStatMutation],
  $baits: [BaitMutation],
  $baitPresets: [BaitPresetAssociation],
  $covers: [CoverMutation],
  $decoys: [DecoyMutation],
  $itemConfigs: [LogItemConfigMutation],
  $trophySightings: [TrophySightingMutation],
  $woundedAnimals: [WoundedMutation],
  $trophyHarvests: [TrophyHarvestMutation],
  $imageIds: [ID],
  $socialPost: SocialPostMutation
) {
  addLog(
    logData: $logData,
    weatherData: $weatherData,
    gameStats: $gameStats,
    baits: $baits,
    baitPresets: $baitPresets,
    covers: $covers,
    decoys: $decoys,
    itemConfigs: $itemConfigs,
    trophySightings: $trophySightings,
    woundedAnimals: $woundedAnimals,
    trophyHarvests: $trophyHarvests,
    imageIds: $imageIds,
    socialPost: $socialPost){
      logId
      badges
      newLevel
      expEarned
      insights{
        personalInsights{
          text
          icon
          color
        }
        buddyInsights{
          text
          icon
          color
        }
      }
      messages
    }
}`;

export const EditLogWeather = gql`
mutation editLogWeather($logId: ID!, $weatherData: WeatherMutation!) {
  editLogWeather(logId: $logId, weatherData: $weatherData)
}`

export const EditLogBasicInfo = gql`
mutation editLogBasicInfo($logId: ID!, $logData: LogMutation!, $updateWeather: Boolean) {
  editLogBasicInfo(logId: $logId, logData: $logData, updateWeather: $updateWeather) 
}`

export const EditLogDetails = gql`
mutation editLogDetails($logId: ID!, $logData: LogMutation!) {
  editLogDetails(logId: $logId, logData: $logData) 
}`

export const AddLogGameStat = gql`
mutation addLogGamestat($logId: ID!, $gameStat: GameStatMutation!) {
  addLogGamestat(logId: $logId, gameStat: $gameStat)
}`

export const EditLogGameStat = gql`
mutation editLogGamestat($logId: ID!, $gameStatId: ID!, $gameStat: GameStatMutation!) {
  editLogGamestat(logId: $logId, gameStatId: $gameStatId, gameStat: $gameStat)
}`

export const DeleteLogGameStat = gql`
mutation deleteLogGameStat($logId: ID!, $gameStatId: ID!) {
  deleteLogGameStat(logId: $logId, gameStatId: $gameStatId)
}`

export const DeleteLog = gql`
mutation DeleteLog($logId: ID!) {
  deleteLog(logId: $logId)
}`

export const AddTrophyHarvest = gql`
mutation addTrophyHarvest($logId: ID!, $trophyHarvest: TrophyHarvestMutation!) {
  addTrophyHarvest(logId: $logId, trophyHarvest: $trophyHarvest)
}`

export const AddBaitPreset = gql`
mutation addBaitPreset($type: String, $name: String, $isLive: Boolean, $primaryColor: String, $secondaryColor: String, $noiseInd: Boolean, $flashInd: Boolean, $smellInd: Boolean, $freshInd: Boolean, $additional: String, $modifications: String, $billType: String, $divingDepth: String, $weight: String){
  addBaitPreset(type: $type, name: $name, isLive: $isLive, primaryColor: $primaryColor, secondaryColor: $secondaryColor, noiseInd: $noiseInd, flashInd: $flashInd, smellInd: $smellInd, freshInd: $freshInd, additional: $additional, modifications: $modifications, billType: $billType, divingDepth: $divingDepth, weight: $weight)
}`

export const EditSpot = gql`
mutation editSpot($spotId: Int!, $name: String, $isPublic: Boolean, $rating: Int, $isDeer: Boolean, $isVarmint: Boolean, $isTurkey: Boolean, $isFishing: Boolean, $isUpland: Boolean, $isWaterfowl: Boolean, $activeInd: Boolean, $buddies: [String]){
  editSpot(spotId: $spotId, name: $name, rating: $rating, isDeer: $isDeer, isVarmint: $isVarmint, isTurkey: $isTurkey, isFishing: $isFishing, isUpland: $isUpland, isWaterfowl: $isWaterfowl, activeInd: $activeInd, isPublic: $isPublic, buddies: $buddies)
}`

export const EditStand = gql`
mutation editStand($standId: Int!, $name: String, $description: String, $activeInd: Boolean, $singleHunterInd: Boolean, $weaponSpecificInd: Boolean, $weapon: String, $huntingOver: String, $accessDirection: String, $winds: [WindMutation]) {
  editStand(standId: $standId, name: $name, description: $description, activeInd: $activeInd, singleHunterInd: $singleHunterInd, weaponSpecificInd: $weaponSpecificInd, weapon: $weapon, huntingOver: $huntingOver, accessDirection: $accessDirection, winds: $winds)
}`

export const EditTrophyHarvest = gql`
mutation editTrophyHarvest($trophyHarvestId: Int!, $age: Float, $weight: Float, $score: Float, $beardLength: Float, $spursLength: Float, $length: Float, $points: Int, $weightOunces: Int, $shotAngle: String, $shotPlacement: String, $distance: Int, $ranDistance: Int, $imageIds: [String]){
  editTrophyHarvest(trophyHarvestId: $trophyHarvestId, age: $age, weight: $weight, score: $score, beardLength: $beardLength, spursLength: $spursLength, length: $length, weightOunces: $weightOunces, points: $points, shotAngle: $shotAngle, shotPlacement: $shotPlacement, distance: $distance, ranDistance: $ranDistance, imageIds: $imageIds)
}`

export const RespondToFriendRequest = gql`
mutation respondToFriendRequest($friendId: Int!, $status: Int!){
  respondFriendRequest(friendId: $friendId, status: $status)
}`

export const SendFriendRequest = gql`
mutation sendFriendRequest($friendId: Int!){
  sendFriendRequest(friendId: $friendId){
    id
    relationship
  }
}`

export const EditPreferences = gql`
mutation EditPreferences($privateProfile: Boolean, $shareTrophyRoom: Boolean, $dashboards: String){
  editPreferences(privateProfile: $privateProfile, shareTrophyRoom: $shareTrophyRoom, dashboards: $dashboards)
}`

export const AddSpot = gql`
mutation addSpot($name: String, $rating: Int, $normalLevel: Float, $isDeer: Boolean, $isTurkey: Boolean, $isWaterfowl: Boolean, $isUpland: Boolean, $isVarmint: Boolean, $isFishing: Boolean, $isPublic: Boolean, $lat: Float, $long: Float, $buddies: [String]){
  addSpot(name: $name, rating: $rating, normalLevel: $normalLevel, isDeer: $isDeer, isTurkey: $isTurkey, isWaterfowl: $isWaterfowl, isUpland: $isUpland, isVarmint: $isVarmint, isFishing: $isFishing, isPublic: $isPublic, lat: $lat, long: $long, buddies: $buddies)
}`

export const CreateNewTrophy = gql`
mutation addTrophy($speciesId: Int!, $spotId: Int!, $name: String, $age: Float, $recognizeFeatures: String, $yearsHistory: Int, $approxScore: Float, $notes: String, $priority: Int, $hitListInd: Boolean, $points: Int, $buddies: [String]){
  addTrophy(speciesId: $speciesId, spotId: $spotId, name: $name, age: $age, recognizeFeatures: $recognizeFeatures, yearsHistory: $yearsHistory, approxScore: $approxScore, notes: $notes, priority: $priority, hitListInd: $hitListInd, points: $points, buddies: $buddies)
}`

export const EditConfiguration = gql`
mutation editConfig($configId: Int!, $name: String!, $reelId: Int, $lineId: Int, $ammoId: Int, $broadheadId: Int, $type: String, $caliber: String, $sight: String, $choke: String, $stabilizer: Int) {
  editConfig(configId: $configId, name: $name, reelId: $reelId, lineId: $lineId, ammoId: $ammoId, broadheadId: $broadheadId, type: $type, caliber: $caliber, sight: $sight, choke: $choke, stabilizer: $stabilizer)
}`

export const DeleteWindPref = gql`
mutation deleteWindPref($windId: Int!){
  deleteWindPref(windId: $windId)
}`

export const DeleteMapFeature = gql`
mutation deleteMapFeature($mapFeatureId: Int!) {
  deleteMapFeature(mapFeatureId: $mapFeatureId)
}`

export const DeleteSpotUser = gql`
mutation deleteSpotUser($spotId: Int!, $userId: Int) {
  deleteSpotUser(spotId: $spotId, userId: $userId)
}`

export const DeleteTrophyUser = gql`
mutation deleteTrophyUser($trophyId: Int!, $userId: Int) {
  deleteTrophyUser(trophyId: $trophyId, userId: $userId)
}`

export const DeleteTrailCamUser = gql`
mutation deleteTrailCamUser($userId: Int, $trailCamId: Int!){
  deleteTrailCamUser(userId: $userId, trailCamId: $trailCamId)
}`

export const DeleteItemConfig = gql`
mutation deleteItemConfig($configId: Int!){
  deleteItemConfig(configId: $configId)
}`

export const DeletePicture = gql`
mutation deletePicture($imageId: Int!){
  deletePicture(imageId: $imageId)
}`

export const DeleteTrophy = gql`
mutation deleteTrophy($trophyId: Int!) {
  deleteTrophy(trophyId: $trophyId)
}`

export const DeleteRelationship = gql`
mutation deleteRelationship($friendId: Int!){
  deleteRelationship(friendId: $friendId)
}`

export const DeleteStand = gql`
mutation deleteStand($standId: Int!){
  deleteStand(standId: $standId)
}`

export const DeleteSpot = gql`
mutation deleteSpot($spotId: Int!){
  deleteSpot(spotId: $spotId)
}`

export const DeleteTrailCam = gql`
mutation deleteTrailCamera($trailCamId: Int!){
  deleteTrailCamera(trailCamId: $trailCamId)
}`

export const DeleteAmmo = gql`
mutation deleteAmmo($ammoId: Int!){
  deleteAmmo(ammoId: $ammoId)
}`

export const DeleteBroadhead = gql`
mutation deleteBroadhead($headId: Int!){
  deleteBroadhead(headId: $headId)
}`

export const DeleteReel = gql`
mutation deleteReel($reelId: Int!){
  deleteReel(reelId: $reelId)
}`

export const DeleteLine = gql`
mutation deleteLine($lineId: Int!){
  deleteLine(lineId: $lineId)
}`

export const DeleteBait = gql`
mutation deleteBaitPreset($baitId: Int!){
  deleteBaitPreset(baitId: $baitId)
}`

export const DeleteGearbagItem = gql`
mutation deleteItem($itemId: Int!){
  deleteItem(itemId: $itemId)
}`

export const DeleteTrailCamSightingLog = gql`
mutation deleteTrailCamSightingLog($logId: Int!) {
  deleteTrailCamSightingLog(trailCamSightingLogId: $logId)
}`

export const DeleteTrophyHarvest = gql`
mutation deleteTrophyHarvest($harvestId: ID!) {
  deleteTrophyHarvest(trophyHarvestId: $harvestId)
}`

export const AddCover = gql`
mutation addCover($logId: ID!, $cover: CoverMutation!){
  addCover(logId: $logId, cover: $cover)
}`

export const DeleteCover = gql`
mutation deleteCover($logId: ID!, $coverId: ID!){
  deleteCover(logId: $logId, coverId: $coverId)
}`

export const AddDecoy = gql`
mutation addDecoy($logId: ID!, $decoy: DecoyMutation!){
  addDecoy(logId: $logId, decoy: $decoy)
}`

export const DeleteDecoy = gql`
mutation deleteDecoy($logId: ID!, $decoyId: ID!){
  deleteDecoy(logId: $logId, decoyId: $decoyId)
}`

export const AddWounded = gql`
mutation addWounded($logId: ID!, $wounded: WoundedMutation!){
  addWounded(logId: $logId, wounded: $wounded)
}`

export const DeleteWounded = gql`
mutation deleteWounded($logId: ID!, $woundedId: ID!){
  deleteWounded(logId: $logId, woundedId: $woundedId)
}`

export const AddBaitToLog = gql`
mutation addBaitToLog($logId: ID!, $bait: BaitMutation!, $baitPreset: BaitPresetAssociation){
  addBaitToLog(logId: $logId, bait: $bait, baitPreset: $baitPreset)
}`

export const AddTrophySeenToLog = gql`
mutation addTrophySeenToLog($logId: ID!, $trophies: [TrophySightingMutation]!){
  addTrophySeenToLog(logId: $logId, trophies: $trophies)
}`

export const DeleteTrophySeenFromLog = gql`
mutation deleteTrophySeenFromLog($logId: ID!, $trophyId: ID!){
  deleteTrophySeenFromLog(logId: $logId, trophyId: $trophyId)
}`

export const DeleteBaitPresetFromLog = gql`
mutation deleteBaitPresetFromLog($logId: ID!, $presetId: ID!){
  deleteBaitPresetFromLog(logId: $logId, presetId: $presetId)
}`

export const DeleteBaitFromLog = gql`
mutation deleteBaitFromLog($logId: ID!, $baitId: ID!){
  deleteBaitFromLog(logId: $logId, baitId: $baitId)
}`

export const DeleteUser = gql`
mutation deleteUser {
  deleteUser
}`

export const AddGlassfyInfo = gql`
mutation addGlassfyInfo($subscriberId: String!) {
  addGlassfyInfo(subscriberId: $subscriberId)
}`

export const AddSocialPost = gql`
mutation addSocialPost($newPost: String!, $pics: [ID], $createdAt: String!){
  addSocialPost(newPost: $newPost, pics: $pics, createdAt: $createdAt)
}`